import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function LoadingElement() { 
    return (
        <h1>
            <Spinner animation="border" variant="secondary" />
            Loading...
        </h1>
    ); 
}  