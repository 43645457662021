// index.js
// Source: https://ui.docs.amplify.aws/react/guides/auth-protected

import { createRoot } from 'react-dom/client';
import App from './App';

import { Amplify } from 'aws-amplify';
import awsExports from "./aws-exports";

import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';

Amplify.configure(awsExports);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);
