import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function LoadingPage() { 
    return (
        <h1>
            <Spinner animation="border" variant="primary" />
            Loading...
        </h1>
    ); 
} 