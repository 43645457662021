/**
 * Converts an ISO date string to the user's specified time zone.
 *
 * @param {string} isoString - The ISO date string to convert.
 * @param {string} userTz - The user's specified time zone.
 * @return {string} The formatted date string in the user's time zone.
 */
export default function convertISODateToUserTz(isoString, userTz) {
    const utc = new Date(isoString);
    const formatter = new Intl.DateTimeFormat('en-UK', {
        timeZone: userTz,
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });
    return formatter.format(utc);
}