// components/Layout.js
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator, Button } from '@aws-amplify/ui-react';

// The Layout should contain the navbar and some structure for every page

export default function Layout() {
  const { route, signOut, user } = useAuthenticator((context) => [
    context.route,
    context.signOut,
    context.user,
  ]);
  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate('/login');
  }

  return (
    <>
      <nav>
        <Button onClick={() => navigate('/')}>Home</Button>
        <Button onClick={() => navigate('/monitoring')}>Monitoring</Button>
        <Button onClick={() => navigate('/downtimes')}>Downtimes</Button>
        {route !== 'authenticated' ? (
          <Button onClick={() => navigate('/login')}>Login</Button>
        ) : (
          <Button onClick={() => logOut()}>Logout ({ user.username })</Button>
        )}
        <img alt="" src="https://cdn.shopify.com/s/files/1/0575/1845/6877/files/Supersight_logo_Blue_2e5da548-e3a6-4575-b2e0-74ff488d2450_240x240.png?v=1670666361" style={{ float: "right", padding: "10px" }}></img>
      </nav>

      <Outlet />
    </>
  );
}
