import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { Button, Modal, Table, Tabs, Tab, Form } from "react-bootstrap";
import { toast } from "react-toastify";

// import logo from './logo.svg';
import "../App.css";
import convertISODateToLastSeen from "../utils/convertISOStringToLastSeen";
import toaster from "../utils/toaster";
import LoadingPage from "./LoadingPage";
import LoadingElement from "./LoadingElement";
import NoReadPermissionPage from "./NoReadPermissionPage";
import MonitoringModal from "./MonitoringModal";

// Permission constants
const permissionRead = "READ";
const permissionUpdate = "UPDATE";

// API constants
const apiName = "supersightApiMvp";
const userIncidentsPath = "/user-incidents";
const userPath = "/user";
const phonePath = "/phone";

function Monitoring() {
  const [username, setUsername] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);
  const [monitoringDict, setMonitoringDict] = useState(null);
  const [timeDataFetched, setTimeDataFetched] = useState(null);
  const [phoneForMonitoringModal, setPhoneForMonitoringModal] = useState(null);

  useEffect(() => {
    if (username !== null) return;

    // Set authenticated user
    const fetchUser = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        setUsername(authenticatedUser.username);
      } catch (err) {
        toaster("Error fetching user.", 2, err);
      }
    };

    fetchUser();
  }, [username]);

  // Get user permissions
  useEffect(() => {
    if (username === null || userPermissions !== null) return;

    // Set user permissions
    const fetchUserPermissions = async () => {
      try {
        const getUserResponse = await API.get(
          apiName,
          `${userPath}/${username}`,
          {}
        );
        const authenticatedUser = JSON.parse(getUserResponse.data);
        setUserPermissions(authenticatedUser.permissions);
      } catch (error) {
        toaster("Error fetching user permissions.", 2, error);
      }
    };

    fetchUserPermissions();
  }, [username, userPermissions]);

  useEffect(() => {
    if (username === null || monitoringDict !== null) return;

    // Set monitoring dict
    const fetchMonitoringDict = async () => {
      try {
        const getResponse = await API.get(
          apiName,
          `${userIncidentsPath}/${username}`,
          {}
        );

        if (!getResponse.success) {
          toaster("Error fetching user monitoring dict.", 2, getResponse.error);
          return;
        }

        const data = JSON.parse(getResponse.data);

        if (
          data.warningPhonesNotResolved &&
          data.warningPhonesNotResolved > 0
        ) {
          toaster(
            data.warningPhonesNotResolved === 1
              ? `One phone was not retrieved, please consider reloading the page.`
              : `${data.warningPhonesNotResolved} phones were not retrieved, please consider reloading the page.`,
            1
          );
        }

        delete data["warningPhonesNotResolved"];

        setMonitoringDict(data);
        setTimeDataFetched(new Date());
      } catch (err) {
        toaster("Error fetching user monitoring dict.", 2, err);
      }
    };

    fetchMonitoringDict();
  }, [username, monitoringDict]);

  const handleSwitchChange = (e, phone) => {
    e.target.disabled = true;

    // Make API call to change phone status
    API.put(apiName, phonePath, {
      body: {
        id: phone.id, // Required
        name: phone.name, // Required
        isActive: !phone.isActive, // Value to update
      },
    }).then((res) => {
      e.target.disabled = false;

      if (!res.success) {
        toaster("Could not update the backend.", 2);
        return;
      }

      // Update monitoring dict
      const updatedMonitoringDict = { ...monitoringDict };
      const updatedOrg = updatedMonitoringDict[phone.organizationName];
      const indexOfUpdatedPhone = updatedOrg.findIndex(
        (p) => p.id === phone.id
      );
      updatedMonitoringDict[phone.organizationName][
        indexOfUpdatedPhone
      ].isActive = !phone.isActive;
      setMonitoringDict(updatedMonitoringDict);
    });
  };

  const getDateStringToday = () => {
    return new Date().toISOString().slice(0, 10);
  };

  const getDateStringSevenDaysAgo = () => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    return sevenDaysAgo.toISOString().slice(0, 10);
  };

  if (
    username === null ||
    userPermissions === null ||
    timeDataFetched === null
  ) {
    return <LoadingPage />;
  } else if (!userPermissions.includes(permissionRead)) {
    return <NoReadPermissionPage />;
  } else if (
    monitoringDict !== null &&
    Object.keys(monitoringDict).length === 0
  ) {
    return (
      <div>
        <h1>Monitoring</h1>
        <p>You don't have any active deployed phones.</p>
      </div>
    );
  }

  return (
    <div>
      <h1>Monitoring (data at {timeDataFetched.toLocaleString()})</h1>
      <p>
        Here you can see the status of your deployed phones. <br />
        Click on the phone name to see the monitoring data in the past 7 days.
      </p>

      {/* Table with current incidents */}
      <h2>Current incidents</h2>
      {monitoringDict === null || timeDataFetched === null ? (
        <LoadingElement />
      ) : []
          .concat(...Object.values(monitoringDict))
          .filter(
            (phone) =>
              (phone.isIncident || phone.isBatteryTemperatureDropped) &&
              phone.isActive
          ).length === 0 ? (
        <p>No incidents at the moment.</p>
      ) : (
        <Table hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Organization</th>
              <th>App version</th>
              <th>Last seen (detecting)</th>
              <th>Last seen (counting)</th>
              <th>Last seen</th>
            </tr>
          </thead>
          <tbody>
            {[]
              .concat(...Object.values(monitoringDict))
              .filter(
                (phone) =>
                  (phone.isIncident || phone.isBatteryTemperatureDropped) &&
                  phone.isActive
              )
              .sort((a, b) => {
                const aName = a.name[0] + a.name.substring(1).padStart(4, "0");
                const bName = b.name[0] + b.name.substring(1).padStart(4, "0");

                return aName.localeCompare(bName);
              })
              .map((phone) => (
                <tr
                  key={phone.id}
                  className={
                    // If the battery temperature dropped, make the row blue
                    phone.isBatteryTemperatureDropped
                      ? "table-primary"
                      : // If the phone hasn't been seen at all for more than 10 minutes, make the row red, otherwise yellow
                      Math.floor(
                          (timeDataFetched.getTime() -
                            new Date(phone.lastSeen).getTime()) /
                            60000
                        ) >= 10
                      ? "table-danger"
                      : "table-warning"
                  }
                >
                  <td>
                    <a
                      href="#"
                      onClick={() => setPhoneForMonitoringModal(phone)}
                    >
                      {phone.name}
                    </a>
                  </td>
                  <td>{phone.organizationName}</td>
                  <td>{phone.appVersion}</td>
                  <td>
                    {convertISODateToLastSeen(
                      phone.lastSeenRunning,
                      timeDataFetched
                    )}
                  </td>
                  <td>
                    {convertISODateToLastSeen(
                      phone.lastSeenCounting,
                      timeDataFetched
                    )}
                  </td>
                  <td>
                    {convertISODateToLastSeen(phone.lastSeen, timeDataFetched)}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <br />

      {/* Tables from each organization */}
      {monitoringDict === null || timeDataFetched === null ? (
        <LoadingElement />
      ) : (
        <div>
          <h2>Organizations</h2>
          {Object.keys(monitoringDict)
            .sort((a, b) => a.localeCompare(b))
            .map((organizationName) => (
              <div key={organizationName}>
                <h3>{organizationName}</h3>
                <Table hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>App version</th>
                      <th>Last seen (detecting)</th>
                      <th>Last seen (counting)</th>
                      <th>Last seen</th>
                      <th>Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...monitoringDict[organizationName]]
                      .sort((a, b) => {
                        const aName =
                          a.name[0] + a.name.substring(1).padStart(4, "0");
                        const bName =
                          b.name[0] + b.name.substring(1).padStart(4, "0");

                        return aName.localeCompare(bName);
                      })
                      .map((phone) => (
                        <tr
                          key={phone.id}
                          className={
                            !phone.isActive || !phone.lastSeenRunning
                              ? "table-secondary"
                              : phone.isBatteryTemperatureDropped
                              ? "table-primary"
                              : Math.floor(
                                  (timeDataFetched.getTime() -
                                    new Date(phone.lastSeen).getTime()) /
                                    60000
                                ) >= 10
                              ? "table-danger"
                              : phone.isIncident
                              ? "table-warning"
                              : "table-success"
                          }
                        >
                          <td>
                            <a
                              href="#"
                              onClick={() => setPhoneForMonitoringModal(phone)}
                            >
                              {phone.name}
                            </a>
                          </td>
                          <td>{phone.appVersion}</td>
                          <td>
                            {convertISODateToLastSeen(
                              phone.lastSeenRunning,
                              timeDataFetched
                            )}
                          </td>
                          <td>
                            {convertISODateToLastSeen(
                              phone.lastSeenCounting,
                              timeDataFetched
                            )}
                          </td>
                          <td>
                            {convertISODateToLastSeen(
                              phone.lastSeen,
                              timeDataFetched
                            )}
                          </td>
                          <td>
                            <Form.Switch
                              checked={phone.isActive ? "y" : ""}
                              onChange={(e) => handleSwitchChange(e, phone)}
                              disabled={
                                userPermissions.includes(permissionUpdate)
                                  ? ""
                                  : "y"
                              }
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <br />
              </div>
            ))}
        </div>
      )}

      {/* Modal for monitoring */}
      {phoneForMonitoringModal !== null && (
        <MonitoringModal
          phoneId={phoneForMonitoringModal.id}
          phoneName={phoneForMonitoringModal.name}
          dateStart={getDateStringSevenDaysAgo()}
          dateEnd={getDateStringToday()}
          handleClose={() => setPhoneForMonitoringModal(null)}
        />
      )}
    </div>
  );
}

export default Monitoring;
