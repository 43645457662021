import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { Button, Modal, Table, Tabs, Tab, Form } from "react-bootstrap";

import "../App.css";
import convertISOStringToUserTz from "../utils/convertISOStringToUserTz";
import toaster from "../utils/toaster";
import NoPage from "./NoPage";
import LoadingPage from "./LoadingPage";
import LoadingElement from "./LoadingElement";
import NoReadPermissionPage from "./NoReadPermissionPage";

// API integration source: https://gerard-sans.medium.com/create-a-rest-api-integrated-with-amazon-dynamodb-using-aws-amplify-and-vue-5be746e43c22

// Permission constants
const permissionRead = "READ";
const permissionUpdate = "UPDATE";
const permissionCreate = "CREATE";
const permissionDelete = "DELETE";
// TODO: update also code in backend to check permissions

// API constants
const apiName = "supersightApiMvp";
const userPath = "/user";
const organizationPath = "/organization";
const subscriptionPath = "/subscription";
const phonePath = "/phone";
const peopleCountBucketPath = "/people-count-bucket";

function Organization() {
  const [organization, setOrganization] = useState(null);
  const [authUsername, setAuthUsername] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);
  const [users, setUsers] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [phones, setPhones] = useState(null);

  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [usernameToAdd, setUsernameToAdd] = useState("");

  const [showModalUserToRemove, setShowModalUserToRemove] = useState(false);
  const [usernameToRemove, setUsernameToRemove] = useState("");

  const [showModalSubscriptionToDelete, setShowModalSubscriptionToDelete] =
    useState(false);
  const [subscriptionToDelete, setSubscriptionToDelete] = useState(null);

  const [showModalFileUrl, setShowModalFileUrl] = useState(false);
  const [idForFileUrl, setIdForFileUrl] = useState(null);
  const [date1ForFileUrl, setDate1ForFileUrl] = useState(null);
  const [date2ForFileUrl, setDate2ForFileUrl] = useState(null);
  const [signedFileUrl, setSignedFileUrl] = useState("");
  const [expirationTime, setExpirationTime] = useState(0);

  const { organizationId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Load first authenticated username
  useEffect(() => {
    if (authUsername !== null) return;

    // Set authenticated user's username
    const fetchAuthUsername = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        setAuthUsername(authenticatedUser.username);
      } catch (error) {
        toaster("Error fetching authenticated user.", 2, error);
      }
    };

    fetchAuthUsername();
  }, [authUsername]);

  // Get user permissions
  useEffect(() => {
    if (authUsername === null || userPermissions !== null) return;

    // Set user permissions
    const fetchUserPermissions = async () => {
      try {
        const getUserResponse = await API.get(
          apiName,
          `${userPath}/${authUsername}`,
          {}
        );
        const authenticatedUser = JSON.parse(getUserResponse.data);
        setUserPermissions(authenticatedUser.permissions);
      } catch (error) {
        toaster("Error fetching user permissions.", 2, error);
      }
    };

    fetchUserPermissions();
  }, [authUsername, userPermissions]);

  // Get organization details
  useEffect(() => {
    if (authUsername === null || organization !== null) return;

    if (location.state) {
      setOrganization(location.state.organization);
      setUsers(
        location.state.organization.users.filter(
          (username) => username !== authUsername
        )
      );
    } else {
      const fetchOrganization = async () => {
        try {
          const getOrganizationResponse = await API.get(
            apiName,
            `${organizationPath}/${organizationId}`,
            {}
          );

          if (getOrganizationResponse.success) {
            setOrganization(JSON.parse(getOrganizationResponse.data));
            setUsers(
              JSON.parse(getOrganizationResponse.data).users.filter(
                (username) => username !== authUsername
              )
            );
          }
        } catch (error) {
          setOrganization({});
          setUsers([]);
          toaster("Error fetching organization.", 2, error);
        }
      };

      fetchOrganization();
    }
  }, [authUsername, location.state, organization, organizationId]);

  // Get subscriptions details
  useEffect(() => {
    if (organization === null || subscriptions !== null) return;

    const fetchOrganizationSubscriptions = async () => {
      try {
        if (organization.subscriptions.length === 0) {
          setSubscriptions([]);
          return;
        }

        // Retrieve subscriptions, one by one, if the length is greater than 0
        const getSubscriptionResponses = await Promise.all(
          organization.subscriptions.map((subscriptionId) =>
            API.get(apiName, `${subscriptionPath}/${subscriptionId}`, {})
          )
        );
        const subscriptionsData = await Promise.all(
          getSubscriptionResponses.map((res) => JSON.parse(res.data))
        );

        setSubscriptions(subscriptionsData);
      } catch (error) {
        setSubscriptions([]);
        toaster("Error fetching subscriptions.", 2, error);
      }
    };

    fetchOrganizationSubscriptions();
  }, [organization, subscriptions]);

  // Get phones details
  useEffect(() => {
    if (organization === null) return;

    const fetchOrganizationPhones = async () => {
      try {
        if (organization.phones.length === 0) {
          setPhones([]);
          return;
        }

        // Retrieve subscriptions, one by one, if the length is greater than 0
        const getPhoneResponses = await Promise.all(
          organization.phones.map((phoneId) =>
            API.get(apiName, `${phonePath}/${phoneId}`, {})
          )
        );
        const phonesData = await Promise.all(
          getPhoneResponses.map((res) => JSON.parse(res.data))
        );

        setPhones(phonesData);
      } catch (error) {
        setPhones([]);
        toaster("Error fetching phones.", 2, error);
      }
    };

    fetchOrganizationPhones();
  }, [organization]);

  // Handle adding new user to organization
  const handleAddUser = () => {
    setShowAddUserForm(true);
  };

  const handleSubmitAddUser = () => {
    setShowAddUserForm(false);

    const putUserInOrganization = async () => {
      try {
        if (usernameToAdd === authUsername) {
          toaster("You cannot add yourself.", 2);
          return;
        }

        if (usernameToAdd === "") {
          toaster("Username cannot be empty.", 2);
          return;
        }

        if (organization.users.includes(usernameToAdd)) {
          toaster("User already added to organization.", 2);
          return;
        }

        // Following lines are not needed, as the check is made in the backend
        // const getUserResponse = await API.get(apiName, `${userPath}/${usernameToAdd}`, {});

        // if (!getUserResponse.success) {
        //   toaster('User cannot be found.', 2);
        //   return;
        // }

        // Update the organization
        const organizationUpdated = { ...organization };
        organizationUpdated.users.push(usernameToAdd);

        const putOrganizationResponse = await API.put(
          apiName,
          organizationPath,
          {
            body: {
              username: authUsername, // Required
              id: organizationId, // Required
              users: organizationUpdated.users, // Required, and prop that changes
            },
          }
        );

        if (putOrganizationResponse.success) {
          toaster("User added to organization.");

          // Update organization
          setOrganization(organizationUpdated);

          // Update users in organization
          setUsers(
            organizationUpdated.users.filter(
              (username) => username !== authUsername
            )
          );
        } else
          throw new Error(
            "API call to update organization was not successful."
          );
      } catch (error) {
        toaster("Error adding user.", 2, error);
      }
    };

    putUserInOrganization().then(() => {
      setUsernameToAdd("");
    });
  };

  const handleCancelAddUser = () => {
    setShowAddUserForm(false);
    setUsernameToAdd("");
  };

  // Handle adding a new subscription
  const handleAddSubscription = (event) => {
    const putSubscription = async () => {
      try {
        // TODO: this logic should be in the backend, but leave it here for the moment.
        // Generate name for new subscription
        let subscriptionName = "#0000";

        if (subscriptions !== null && subscriptions.length > 0) {
          let subscriptionsSorted = [...subscriptions].sort((a, b) =>
            a.name.localeCompare(b.name)
          );

          for (const subscription of subscriptionsSorted) {
            if (subscription.name === subscriptionName) {
              const nextNumber = parseInt(subscriptionName.slice(1)) + 1;
              subscriptionName = `#${String(nextNumber).padStart(4, "0")}`;
            } else break;
          }
        }

        event.target.disabled = true;

        // Make API call to save the new subscription in the DB
        const postSubscriptionResponse = await API.post(
          apiName,
          subscriptionPath,
          {
            body: {
              organizationId: organizationId,
              name: subscriptionName,
            },
          }
        );

        if (postSubscriptionResponse.success) {
          // Update the table
          const subscriptionsUpdated =
            subscriptions === null ? [] : [...subscriptions];
          subscriptionsUpdated.push(JSON.parse(postSubscriptionResponse.body));

          setSubscriptions(subscriptionsUpdated);

          // Toast message
          toaster("Subscription successfully added.");
        } else
          throw new Error(
            "Something went wrong while saving the subscription in the DB."
          );
      } catch (error) {
        toaster("Error adding subscription.", 2, error);
      }
    };

    putSubscription().then(() => {
      event.target.disabled = false;
    });
  };

  // Handle remove user from organization
  const handleRemoveUser = (username) => {
    setUsernameToRemove(username);
    setShowModalUserToRemove(true);
  };

  const confirmRemoveUser = () => {
    // Delete org from DB through API call
    const removeUser = async () => {
      try {
        // Following lines are not needed, as the check is made in the backend
        // const getUserResponse = await API.get(
        //   apiName,
        //   `${userPath}/${usernameToRemove}`,
        //   {}
        // );

        // if (!getUserResponse.success) {
        //   toaster("User cannot be found.");
        //   return;
        // }

        // Update the organization
        const organizationUpdated = { ...organization };
        organizationUpdated.users = organization.users.filter(
          (username) => username !== usernameToRemove
        );

        const putOrganizationResponse = await API.put(
          apiName,
          organizationPath,
          {
            body: {
              username: authUsername, // Required
              id: organizationId, // Required
              users: organizationUpdated.users, // Required, and prop that changes
            },
          }
        );

        if (putOrganizationResponse.success) {
          toaster("User removed from organization.");

          // Update organization
          setOrganization(organizationUpdated);

          // Update users in organization
          setUsers(
            organizationUpdated.users.filter(
              (username) => username !== authUsername
            )
          );
        } else
          throw new Error(
            "API call to update organization was not successful."
          );
      } catch (error) {
        toaster("Error removing user.", 2, error);
      }
    };

    removeUser().then(() => {
      setUsernameToRemove("");
      setShowModalUserToRemove(false);
    });
  };

  const cancelRemoveUser = () => {
    setUsernameToRemove("");
    setShowModalUserToRemove(false);
  };

  const handleSwitchChange = (e, phone) => {
    e.target.disabled = true;

    // Make API call to change phone status
    API.put(apiName, phonePath, {
      body: {
        id: phone.id, // Required
        name: phone.name, // Required
        isActive: !phone.isActive, // Value to update
      },
    }).then((res) => {
      e.target.disabled = false;

      if (!res.success) {
        toaster("Could not update the backend.", 2);
        return;
      }

      // Update monitoring dict
      const updatedPhones = [...phones];
      const indexOfUpdatedPhone = updatedPhones.findIndex(
        (p) => p.id === phone.id
      );
      updatedPhones[indexOfUpdatedPhone].isActive = !phone.isActive;
      setPhones(updatedPhones);
    });
  };

  // Handle delete subscription
  const handleDeleteSubscription = (subscription) => {
    setSubscriptionToDelete(subscription);
    setShowModalSubscriptionToDelete(true);
  };

  const confirmDeleteSubscription = () => {
    // Delete org from DB through API call
    const deleteSubscription = async () => {
      try {
        // Make the API call to delete the subscription from the DB
        const delSubscriptionResponse = await API.del(
          apiName,
          `${subscriptionPath}/${subscriptionToDelete.id}`,
          {}
        );

        if (delSubscriptionResponse.success) {
          // Update the table
          const subscriptionsUpdated = subscriptions.filter(
            (subscription) => subscription.id !== subscriptionToDelete.id
          );
          setSubscriptions(subscriptionsUpdated);

          // Update phone table if phone was attached to the subscription
          if (subscriptionToDelete.phoneId !== "") {
            const phonesUpdated = phones.filter(
              (phone) => phone.id !== subscriptionToDelete.phoneId
            );
            setPhones(phonesUpdated);
          }

          // Toast message
          toaster("Subscription successfully removed.");
        } else
          throw new Error(
            "Something went wrong while deleting the subscription in the DB."
          );
      } catch (error) {
        toaster("Error deleting subscription.", 2, error);
      }
    };

    deleteSubscription().then(() => {
      setShowModalSubscriptionToDelete(false);
      setSubscriptionToDelete(null);
    });
  };

  const cancelDeleteSubscription = () => {
    setSubscriptionToDelete(null);
    setShowModalSubscriptionToDelete(false);
  };

  // Open modal to specify dates and get signed url
  const openModalFileUrl = (id = undefined) => {
    setShowModalFileUrl(true);
    setIdForFileUrl(id);
  };

  // Handle get file url
  const getFileUrl = (event) => {
    if (idForFileUrl === null) {
      toaster(`Error, idForFileUrl is null`, 2);
      return;
    }

    // Make API call to get the signed file url
    const apiCall = async (button, id) => {
      const prevButtonText = button.innerText;
      button.innerText = "Loading...";

      try {
        let getFileUrl;

        if (date1ForFileUrl !== null && date2ForFileUrl !== null) {
          getFileUrl = await API.get(
            apiName,
            `${peopleCountBucketPath}?organizationId=${organization.id}` +
              (id === undefined ? "" : `&phoneId=${id}`) +
              `&dateStart=${date1ForFileUrl}&dateEnd=${date2ForFileUrl}`,
            {}
          );
        } else if (date1ForFileUrl !== null && date2ForFileUrl === null) {
          getFileUrl = await API.get(
            apiName,
            `${peopleCountBucketPath}?organizationId=${organization.id}` +
              (id === undefined ? "" : `&phoneId=${id}`) +
              `&date=${date1ForFileUrl}`,
            {}
          );
        } else {
          toaster(`Error, dates are not passed correctly`, 2);
          return;
        }

        if (getFileUrl.success) {
          setSignedFileUrl(getFileUrl.data);
          setExpirationTime(getFileUrl.expiration);
          setDate1ForFileUrl(null);
          setDate2ForFileUrl(null);
        } else {
          toaster("The URL for the bucket could not be generated.", 2);
        }
      } catch (error) {
        toaster("Error in the API call.", 2, error);
      }

      button.innerText = prevButtonText;
    };

    apiCall(event.target, idForFileUrl);
  };

  const closeModalFileUrl = () => {
    setIdForFileUrl(null);
    setDate1ForFileUrl(null);
    setDate2ForFileUrl(null);
    setSignedFileUrl("");
    setExpirationTime(0);
    setShowModalFileUrl(false);
  };

  if (
    authUsername === null ||
    userPermissions === null ||
    organization === null
  ) {
    return <LoadingPage />;
  } else if (!userPermissions.includes(permissionRead)) {
    return <NoReadPermissionPage />;
  } else if (Object.keys(organization).length === 0) {
    return <NoPage />;
  }

  return (
    <div>
      <h1>Organization Name: {organization.name}</h1>
      {/* Add User form */}
      {showAddUserForm ? (
        <div>
          <label htmlFor="usernameToAdd">
            New user's username to add to this organization:
          </label>
          <input
            type="text"
            id="usernameToAdd"
            value={usernameToAdd}
            onChange={(e) => setUsernameToAdd(e.target.value)}
          />
          <Button onClick={() => handleSubmitAddUser()} variant="primary">
            Submit
          </Button>
          <Button onClick={() => handleCancelAddUser()} variant="link">
            Cancel
          </Button>
          <br />
          <br />
        </div>
      ) : userPermissions !== null &&
        userPermissions.includes(permissionUpdate) ? (
        <div>
          <Button onClick={() => handleAddUser()} variant="primary">
            <span style={{ marginRight: "5px" }}>+</span>
            Add User
          </Button>
          <br />
          <br />
        </div>
      ) : (
        <></>
      )}

      {/* Add Subscription button */}
      {userPermissions !== null &&
      userPermissions.includes(permissionCreate) ? (
        <div>
          <Button variant="primary" onClick={(e) => handleAddSubscription(e)}>
            <span style={{ marginRight: "5px" }}>+</span>
            Add Subscription
          </Button>
          <br />
          <br />
        </div>
      ) : (
        <></>
      )}

      <h2>Other users in this organization</h2>
      {/* Users table */}
      {users === null ? (
        <LoadingElement />
      ) : users.length === 0 ? (
        <p>No other users are in this organization yet.</p>
      ) : (
        <Table hover>
          <thead>
            <tr>
              <th>Username</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users
              .sort((a, b) => a.localeCompare(b))
              .map((username) => (
                <tr key={`row-${username}`}>
                  <td>{username}</td>
                  <td>
                    {userPermissions !== null &&
                    userPermissions.includes(permissionDelete) ? (
                      <Button
                        onClick={() => handleRemoveUser(username)}
                        variant="danger"
                        size="sm"
                      >
                        Remove
                      </Button>
                    ) : (
                      <>No actions available</>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <br />

      {/* Modal user to remove */}
      {showModalUserToRemove && (
        <Modal show={showModalUserToRemove} onHide={() => cancelRemoveUser()}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Remove</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove the user {usernameToRemove} from
            this organization?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => cancelRemoveUser()}>
              No
            </Button>
            <Button variant="primary" onClick={() => confirmRemoveUser()}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <h2>Subscriptions table</h2>
      {/* Subscriptions table */}
      {subscriptions === null ? (
        <LoadingElement />
      ) : subscriptions.length === 0 ? (
        <p>No subscriptions available yet</p>
      ) : (
        <Table hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone connected</th>
              <th>Date created</th>
              <th>Date activated</th>
              <th>Expiration date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {subscriptions
              .sort((a, b) => {
                const aPhoneName = a.phoneName
                  ? a.phoneName[0] + a.phoneName.substring(1).padStart(4, "0")
                  : undefined;
                const bPhoneName = b.phoneName
                  ? b.phoneName[0] + b.phoneName.substring(1).padStart(4, "0")
                  : undefined;

                if (aPhoneName && bPhoneName) {
                  return aPhoneName.localeCompare(bPhoneName);
                } else if (aPhoneName && !bPhoneName) {
                  return -1;
                } else if (!aPhoneName && bPhoneName) {
                  return 1;
                } else {
                  return a.name.localeCompare(b.name);
                }
              })
              .map((subscription) => (
                <tr key={subscription.id}>
                  <td>{subscription.name}</td>
                  <td>
                    {subscription.phoneName ? subscription.phoneName : "None"}
                  </td>
                  <td>
                    {convertISOStringToUserTz(
                      subscription.creationDate,
                      userTimeZone
                    )}
                  </td>
                  <td>
                    {convertISOStringToUserTz(
                      subscription.activationDate,
                      userTimeZone
                    )}
                  </td>
                  <td>
                    {convertISOStringToUserTz(
                      subscription.expirationDate,
                      userTimeZone
                    )}
                  </td>
                  <td>
                    {userPermissions !== null &&
                    userPermissions.includes(permissionDelete) ? (
                      <Button
                        onClick={() => handleDeleteSubscription(subscription)}
                        variant="danger"
                        size="sm"
                      >
                        Delete
                      </Button>
                    ) : (
                      <>No actions available</>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <br />

      {/* Modal subscription to delete */}
      {showModalSubscriptionToDelete && (
        <Modal
          show={showModalSubscriptionToDelete}
          onHide={() => cancelDeleteSubscription()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the subscription? If a phone is
            connected to this subscription, the phone will be deleted as well
            from the database, but the data collected will not be deleted.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => cancelDeleteSubscription()}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => confirmDeleteSubscription()}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <div className="d-flex justify-content-start">
        <h2>Phones table</h2>
        <Button
          className="mx-3"
          variant="primary"
          onClick={() => openModalFileUrl()}
        >
          Download report
        </Button>
      </div>
      {/* Phones table */}
      {phones === null ? (
        <LoadingElement />
      ) : phones.length === 0 ? (
        <p>No phones available yet</p>
      ) : (
        <Table hover>
          <thead>
            <tr>
              <th>Phone name</th>
              <th>Location</th>
              <th>App</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {phones
              .sort((a, b) => {
                const aName = a.name[0] + a.name.substring(1).padStart(4, "0");
                const bName = b.name[0] + b.name.substring(1).padStart(4, "0");

                return aName.localeCompare(bName);
              })
              .map((phone) => (
                <tr key={phone.name}>
                  <td>{phone.name}</td>
                  <td>{phone.location}</td>
                  <td>{phone.appVersion}</td>
                  <td>
                    <Form.Switch
                      checked={phone.isActive ? "y" : ""}
                      onChange={(e) => handleSwitchChange(e, phone)}
                      disabled={
                        userPermissions.includes(permissionUpdate) ? "" : "y"
                      }
                    />
                  </td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => openModalFileUrl(phone.id)}
                    >
                      Download report
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}

      {/* Modal get file url */}
      {showModalFileUrl && (
        <Modal show={showModalFileUrl} onHide={() => closeModalFileUrl()}>
          <Modal.Header closeButton>
            <Modal.Title>Download reports</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultActiveKey="rangeDate" className="mb-3">
              <Tab eventKey="singleDate" title="Data from one day">
                <Form.Group controlId="singleDate">
                  <Form.Control
                    type="date"
                    name="date"
                    placeholder={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => {
                      const date = new Date(e.target.value);
                      const currentDate = new Date();
                      if (
                        date.toISOString().slice(0, 10) >
                        currentDate.toISOString().slice(0, 10)
                      ) {
                        toaster(
                          "The selected date is in the future, please select another one.",
                          2
                        );
                        setDate1ForFileUrl(null);
                        setDate2ForFileUrl(null);

                        return;
                      }

                      setDate1ForFileUrl(date.toISOString().slice(0, 10));
                      setDate2ForFileUrl(null);
                    }}
                  />
                </Form.Group>
                {date1ForFileUrl !== null && date2ForFileUrl === null && (
                  <Button
                    variant="primary"
                    onClick={(event) => getFileUrl(event)}
                  >
                    Get link
                  </Button>
                )}
              </Tab>
              <Tab eventKey="rangeDate" title="Data from range of dates">
                <Form.Group controlId="rangeDate">
                  <Form.Control
                    type="date"
                    name="dateStart"
                    placeholder={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => {
                      const date = new Date(e.target.value);
                      const currentDate = new Date();
                      if (
                        date.toISOString().slice(0, 10) >
                        currentDate.toISOString().slice(0, 10)
                      ) {
                        toaster(
                          "The selected date is in the future, please select another one.",
                          2
                        );
                        setDate1ForFileUrl(null);

                        return;
                      }

                      if (
                        date2ForFileUrl !== null &&
                        date.toISOString().slice(0, 10) >= date2ForFileUrl
                      ) {
                        toaster(
                          "The start date must be before the end date, please select another one.",
                          2
                        );
                        setDate1ForFileUrl(null);

                        return;
                      }

                      setDate1ForFileUrl(date.toISOString().slice(0, 10));
                    }}
                  />
                  <Form.Control
                    type="date"
                    name="dateEnd"
                    placeholder={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => {
                      const date = new Date(e.target.value);
                      const currentDate = new Date();
                      if (
                        date.toISOString().slice(0, 10) >
                        currentDate.toISOString().slice(0, 10)
                      ) {
                        toaster(
                          "The selected date is in the future, please select another one.",
                          2
                        );
                        setDate2ForFileUrl(null);

                        return;
                      }

                      if (
                        date1ForFileUrl !== null &&
                        date.toISOString().slice(0, 10) <= date1ForFileUrl
                      ) {
                        toaster(
                          "The end date must be after the start date, please select another one.",
                          2
                        );
                        setDate2ForFileUrl(null);

                        return;
                      }

                      setDate2ForFileUrl(date.toISOString().slice(0, 10));
                    }}
                  />
                </Form.Group>
                {date1ForFileUrl !== null && date2ForFileUrl !== null && (
                  <Button
                    variant="primary"
                    onClick={(event) => getFileUrl(event)}
                  >
                    Get link
                  </Button>
                )}
              </Tab>
            </Tabs>
            {signedFileUrl === "" ? (
              <p>Select dates.</p>
            ) : signedFileUrl === "None" ? (
              <p>There is no data available for the selected dates.</p>
            ) : (
              <p>
                Click <a href={signedFileUrl}>here</a> to download the report.
                The link is available for {Math.floor(expirationTime / 60)}{" "}
                minutes.
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => closeModalFileUrl()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default Organization;
