import React, { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Modal, Table, Tabs, Tab, Form } from 'react-bootstrap';

// import logo from './logo.svg';
import '../App.css';
import toaster from '../utils/toaster';
import LoadingPage from './LoadingPage';
import LoadingElement from './LoadingElement';
import NoReadPermissionPage from './NoReadPermissionPage';
import computeDowntime from '../utils/computeDowntime';
import MonitoringModal from './MonitoringModal';

// Permission constants
const permissionRead = "READ";
const permissionDelete = "DELETE";

// API constants
const apiName = 'supersightApiMvp';
const userPath = '/user';
const downtimePath = '/downtime';
// TODO: add all logic for getting measurements in a nice graph

function Downtimes() {
  const [username, setUsername] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);
  const [downtimes, setDowntimes] = useState(null);
  const [downtimeForMonitoringModal, setDowntimeForMonitoringModal] = useState(null);

  useEffect(() => {
    if (username !== null) return;

    // Set authenticated user
    const fetchUser = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        setUsername(authenticatedUser.username);
      } catch (err) {
        toaster('Error fetching user.', 2, err);
      }
    };

    fetchUser();
  }, [username]);

  // Get user permissions
  useEffect(() => {
    if (username === null || userPermissions !== null) return;

    // Set user permissions
    const fetchUserPermissions = async () => {
      try {
        const getUserResponse = await API.get(apiName, `${userPath}/${username}`, {});
        const authenticatedUser = JSON.parse(getUserResponse.data);
        setUserPermissions(authenticatedUser.permissions);
      } catch (error) {
        toaster('Error fetching user permissions.', 2, error);
      }
    }

    fetchUserPermissions();
  }, [username, userPermissions]);

  useEffect(() => {
    if (username === null || downtimes !== null) return;

    // Set downtimes
    const fetchDowntimes = async () => {
      try {
        const getResponse = await API.get(apiName, `${downtimePath}/${username}`, {});

        if (!getResponse.success) {
          toaster('Error fetching user downtimes.', 2, getResponse.error);
          return;
        }

        const data = JSON.parse(getResponse.data);

        if (data.warningPhonesNotResolved > 0) {
          toaster(
            data.warningPhonesNotResolved === 1 ? (
              `Downtimes for one phone were not retrieved, please consider reloading the page.`
            ) : (
              `Downtimes for ${data.warningPhonesNotResolved} phones were not retrieved, please consider reloading the page.`
            ), 1
          )
        }

        setDowntimes(data.downtimes);
      } catch (err) {
        toaster('Error fetching user downtimes.', 2, err);
      }
    };

    fetchDowntimes();
  }, [username, downtimes]);

  const handleDeleteDowntime = (e, downtime) => {
    e.target.disabled = true;

    // Make API call to change phone status
    API.del(apiName, `${downtimePath}/${downtime.phoneId}/${downtime.dateCreated}`, {})
      .then((res) => {
        e.target.disabled = false;

        if (!res.success) {
          toaster("Could not delete the downtime in the backend.", 2);
          return;
        }

        // Update downtimes by removing the downtime just deleted in the backend
        let updatedDowntimes = [...downtimes];
        const indexOfDeletedDowntime = downtimes.findIndex(d => d.phoneId === downtime.phoneId && d.dateCreated === downtime.dateCreated);
        updatedDowntimes.splice(indexOfDeletedDowntime, 1);
        setDowntimes(updatedDowntimes);
      })
  }

  if (username === null || userPermissions === null) {
    return <LoadingPage />;
  } else if (!userPermissions.includes(permissionRead)) {
    return <NoReadPermissionPage />;
  } else if (downtimes !== null && downtimes.length === 0) {
    return (
      <div>
        <h1>Downtimes</h1>
        <p>There are no downtimes to display in the last 30 days.</p>
      </div>
    )
  }

  return (
    <div>
      <h1>Downtimes</h1>
      <p>
        Here you can see the downtimes of your deployed phones in the last 30 days. <br />
        Click on a phone name to see the monitoring data collected around the incident.
      </p>

      {/* Table with downtimes */}
      {downtimes === null ? (
        <LoadingElement />
      ) : (
        <Table hover>
          <thead>
            <tr>
              <th>Phone</th>
              <th>Organization</th>
              <th>Started at</th>
              <th>Duration</th>
              <th>Ended at</th>
              <th>Incident #</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {downtimes.map((downtime) => (
              <tr key={`${downtime.phoneId}-${downtime.dateCreated}`}>
                <td>
                  <a href="#" onClick={() => {
                    const d = {...downtime};
                    
                    // Get one day before the incident started
                    const oneDayBeforeIncident = new Date(d.incidentStartedAt);
                    oneDayBeforeIncident.setDate(oneDayBeforeIncident.getDate() - 1);
                    d.oneDayBeforeIncident = oneDayBeforeIncident.toISOString();

                    // Get one day after the incident was resolved
                    const oneDayAfterIncident = new Date(d.dateCreated);
                    oneDayAfterIncident.setDate(oneDayAfterIncident.getDate() + 1);
                    d.oneDayAfterIncident = oneDayAfterIncident.toISOString();

                    setDowntimeForMonitoringModal(d);
                  }}>{downtime.phoneName}</a>
                </td>
                <td>{downtime.organizationName}</td>
                <td>{new Date(downtime.incidentStartedAt).toLocaleString()}</td>
                <td>{computeDowntime(downtime.incidentStartedAt, downtime.dateCreated)}</td>
                <td>{new Date(downtime.dateCreated).toLocaleString()}</td>
                <td>{downtime.index}</td>
                <td>
                  { userPermissions !== null && userPermissions.includes(permissionDelete) ? (
                    <Button onClick={(e) => handleDeleteDowntime(e, downtime)} variant="danger" size="sm">Delete</Button>
                  ) : (
                    <>No actions available</>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Modal for monitoring */}
      { downtimeForMonitoringModal !== null && (
        <MonitoringModal 
          phoneId={downtimeForMonitoringModal.phoneId} 
          phoneName={downtimeForMonitoringModal.phoneName}
          dateStart={downtimeForMonitoringModal.oneDayBeforeIncident}
          dateEnd={downtimeForMonitoringModal.oneDayAfterIncident}
          handleClose={() => setDowntimeForMonitoringModal(null)} />
      )}

    </div>
  )
}

export default Downtimes;