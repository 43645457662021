/**
 * Compute the downtime between two given ISO strings.
 *
 * @param {string} isoString1 - The first ISO string representing a date.
 * @param {string} isoString2 - The second ISO string representing a date.
 * @return {string|boolean} The computed downtime as a string in the format "Xd Xh Xm" or false if the input dates are invalid or if the first input date is after the second input date.
 */
export default function computeDowntime(isoString1, isoString2) {
    // Check if the input dates are valid
    if (!Date.parse(isoString1) || !Date.parse(isoString2)) {
        return false; // Invalid dates
    }

    // Check if the first input date is after the second input date, and if so, return false
    if (new Date(isoString1) > new Date(isoString2)) {
        return false;
    }

    const time1 = new Date(isoString1).getTime();
    const time2 = new Date(isoString2).getTime();

    let computedDowntime = time2 - time1;
    let computedDowntimeString = '';
    
    // 24 h/d * 3600000 millisecs/h = 86400000 millisecs/d
    if (Math.floor((computedDowntime) / 86400000) > 0) {
        computedDowntimeString += `${Math.floor((computedDowntime) / 86400000)}d `;
        computedDowntime = computedDowntime % 86400000;
    }
    
    if (Math.floor((computedDowntime) / 3600000) > 0) {
        computedDowntimeString += `${Math.floor((computedDowntime) / 3600000)}h `;
        computedDowntime = computedDowntime % 3600000;
    } 
    
    computedDowntimeString += `${Math.floor((computedDowntime) / 60000)}m `;
    return computedDowntimeString;
}