/**
 * Converts an ISO date string to a string representing the time elapsed since the date.
 *
 * @param {string} isoString - The ISO date string to convert.
 * @param {string} until - The date to compare to. If not provided, the current date is used.
 * @return {string} The time elapsed since the date in the format of "Xd" for days, "Xh" for hours, or "Xm" for minutes.
 */
export default function convertISODateToLastSeen(isoString, until = undefined) {
    if (!isoString) {
        return 'Never seen';
    }

    const now = until ? until.getTime() : new Date().getTime();
    const then = new Date(isoString).getTime();
    
    // 24 h/d * 3600000 millisecs/h = 86400000 millisecs/d
    if (Math.floor((now - then) / 86400000) > 0) {
        return Math.floor((now - then) / 86400000) + 'd';
    } else if (Math.floor((now - then) / 3600000) > 0) {
        return Math.floor((now - then) / 3600000) + 'h';
    } else {
        return Math.floor((now - then) / 60000) + 'm';
    }
}