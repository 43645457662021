// App.js
// Source: https://ui.docs.amplify.aws/react/guides/auth-protected

import { Authenticator } from '@aws-amplify/ui-react';

import RequireAuth from './RequireAuth';
import Login from './components/Login';
import Home from './components/Home';
import Organization from './components/Organization';
import NoPage from './components/NoPage';
import Layout from './components/Layout';
import Monitoring from './components/Monitoring';
import Downtimes from './components/Downtimes';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          } />
        </Route>
        <Route path="/organization/:organizationId" element={<Layout />}>
          <Route index element={
            <RequireAuth>
              <Organization />
            </RequireAuth>
          } />
        </Route>
        <Route path="/monitoring" element={<Layout />}>
          <Route index element={
            <RequireAuth>
              <Monitoring />
            </RequireAuth>
          } />
        </Route>
        <Route path="/downtimes" element={<Layout />}>
          <Route index element={
            <RequireAuth>
              <Downtimes />
            </RequireAuth>
          } />
        </Route>
        <Route path="/login" element={<Layout />}>
          <Route index element={<Login />} />
        </Route>
        <Route path="*" element={<Layout />}>
          <Route index element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <>
      <ToastContainer />
      <Authenticator.Provider>
        <MyRoutes />
      </Authenticator.Provider>
    </>
  );
}

export default App;
