import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { Table, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import '../App.css';
import toaster from '../utils/toaster';
import LoadingPage from './LoadingPage';
import NoReadPermissionPage from './NoReadPermissionPage';

// API integration source: https://gerard-sans.medium.com/create-a-rest-api-integrated-with-amazon-dynamodb-using-aws-amplify-and-vue-5be746e43c22

// Permission constants
const permissionRead = "READ";
const permissionUpdate = "UPDATE";
const permissionCreate = "CREATE";
const permissionDelete = "DELETE";

// API constants
const apiName = 'supersightApiMvp';
const organizationPath = '/organization';
const userPath = '/user';

function Home() {
  const [user, setUser] = useState(null);
  const [organizations, setOrganizations] = useState(null);

  const [showAddOrganizationForm, setShowAddOrganizationForm] = useState(false);
  const [newOrganizationName, setNewOrganizationName] = useState("");

  const [showModalOrganizationToDelete, setShowModalOrganizationToDelete] = useState(false);
  const [organizationToDelete, setOrganizationToDelete] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (user !== null) return;

    // Set authenticated user
    const fetchUser = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        const getUserResponse = await API.get(apiName, `${userPath}/${authenticatedUser.username}`, {});
        const data = JSON.parse(getUserResponse.data);

        if (getUserResponse.success) {
          setUser(data);
        } else throw new Error('User response was not successful.');
      } catch (err) {
        toaster('Error fetching user.', 2, err);
      }
    };

    fetchUser();
  }, [user]);

  useEffect(() => {
    if (user === null || organizations !== null) return;
    
    const fetchUserOrganizations = async () => {
      try {
        const userOrganizations = user.organizations;

        if (userOrganizations) {
          // Retrieve organizations, one by one
          const getOrganizationResponses = await Promise.all(userOrganizations.map(organizationId => API.get(apiName, `${organizationPath}/${organizationId}`, {})));
          const organizationsData = await Promise.all(getOrganizationResponses.map(res => res.success ? JSON.parse(res.data) : null));

          // Filter results if some responses were unsuccessful, and sort by name
          organizationsData.filter(org => org !== null).sort((a, b) => {
            const nameA = a.name;
            const nameB = b.name;
          
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });

          setOrganizations(organizationsData);
        } else {
          setOrganizations([]);
        }
      } catch (err) {
        setOrganizations([]);
        toaster('Error fetching organizations.', 2, err);
      }
    };

    fetchUserOrganizations();
  }, [user, organizations]);

  const handleAddOrganization = () => {
    setShowAddOrganizationForm(true);
  };

  /**
   * Asynchronously sends a POST request to the specified API endpoint to add a new organization.
   *
   * @return {Promise<void>} This function does not return anything.
   */
  const handleSubmitAddOrganization = () => {
    setShowAddOrganizationForm(false);
    
    const putOrganization = async () => {
      try {
        const postOrganizationResponse = await API.post(apiName, organizationPath, {
          body: {
            name: newOrganizationName.toLowerCase(), // Required
            username: user.username // Required
          },
        });
        
        if (postOrganizationResponse.success) {
          // Update the table
          let organizationsUpdated = [...organizations];
          organizationsUpdated.push(JSON.parse(postOrganizationResponse.body));
          
          // Sort it by name
          organizationsUpdated.sort((a, b) => {
            const nameA = a.name;
            const nameB = b.name;
          
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });

          setOrganizations(organizationsUpdated);
          
          // Toast message
          toaster("Organization added!");
        } else throw new Error('API call to add organization was not successful.');
      } catch (err) {
        toaster('Error adding organization.', 2, err);
      }
    };

    putOrganization()
    .then(() => {
      setNewOrganizationName("");
    });
  };

  const handleCancelAddOrganization = () => {
    setShowAddOrganizationForm(false);
    setNewOrganizationName("");
  };

  const handleOrganizationClick = (organization) => {
    navigate(`/organization/${organization.id}`);
  };

  const handleDeleteOrganization = (organization) => {
    setOrganizationToDelete(organization);
    setShowModalOrganizationToDelete(true);
  };

  const confirmDeleteOrganization = () => {
    // Delete org from DB through API call
    const deleteOrganization = async () => {
      try {
        const delOrganizationResponse = await API.del(
          apiName, 
          `${organizationPath}/${organizationToDelete.id}`, 
          { 
            body: { 
              users: organizationToDelete.users,
              username: user.username 
            }
          }
        );
        
        if (delOrganizationResponse.success) {
          // Toast message
          toaster("Organization deleted.");

          // Update the table
          const organizationsUpdated = organizations.filter(org => org.id !== organizationToDelete.id);
          setOrganizations(organizationsUpdated);
        }
      } catch (error) {
        toaster('Error deleting organization.', 2, error);
      }
    };

    deleteOrganization()
    .then(() => {
      setShowModalOrganizationToDelete(false);
      setOrganizationToDelete(null);
    })
  };

  const cancelDeleteOrganization = () => {
    setOrganizationToDelete(null);
    setShowModalOrganizationToDelete(false);
  };

  if (user === null || organizations === null) {
    return <LoadingPage />;
  } else if (!user.permissions.includes(permissionRead)) {
    return <NoReadPermissionPage />;
  } else if (organizations.length === 0) {
    return (
      <div>
        <h1>Welcome, {user.username}</h1>
        <p>No organizations available yet, ask an admin to add you to an organization.</p>
      </div>
    )
  }
  
  return (
    <div>
      { user ? (
        <h1>Welcome, {user.username}</h1>
      ) : (
        <h1>Not logged in</h1>
      )}

      {/* Add Organization Button */}
      { showAddOrganizationForm ? (
        <div>
          <label htmlFor="organizationName">New organization name:</label>
          <input
            type="text"
            id="organizationName"
            value={newOrganizationName}
            onChange={(e) => setNewOrganizationName(e.target.value)}
          />
          <Button onClick={handleSubmitAddOrganization} variant="primary">
            Submit
          </Button>
          <Button onClick={handleCancelAddOrganization} variant="link">
            Cancel
          </Button>
        </div>
      ) : user !== null && user.permissions.includes(permissionCreate) ? (
        <Button onClick={handleAddOrganization} variant="primary">
          <span style={{ marginRight: "5px" }}>+</span>
          Add Organization
        </Button>
      ) : (
        <></>
      )}


      {/* Organizations table */}
      <h2>Organizations</h2>
      { organizations === null ? (
        <LoadingPage />
      ) : organizations.length === 0 ? (
        <p>No organizations available yet.</p>
      ) : (
        <Table hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>User</th>
              <th>Subscriptions</th>
              <th>Phones</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {organizations.filter(org => org !== null)
            .sort((a, b) => (a.name.localeCompare(b.name)))
            .map((organization) => (
              <tr key={organization.id}>
                <td>
                  <Link 
                  to={`/organization/${organization.id}`} 
                  className="text-decoration-none text-primary" 
                  state={{ organization: organization }} 
                  onClick={() => handleOrganizationClick(organization)}>
                    {organization.name}
                  </Link>
                </td>
                <td>{organization.users.length}</td>
                <td>{organization.subscriptions.length}</td>
                <td>{organization.phones.length}</td>
                <td>
                  { user !== null && user.permissions.includes(permissionDelete) ? (
                    <Button onClick={() => handleDeleteOrganization(organization)} variant="danger" size="sm">Delete</Button>
                  ) : (
                    <>No actions available</>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Modal organization to delete */}
      { showModalOrganizationToDelete && (
        <Modal show={showModalOrganizationToDelete} onHide={cancelDeleteOrganization}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the organization?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDeleteOrganization}>
            No
          </Button>
          <Button variant="primary" onClick={confirmDeleteOrganization}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      )}
    </div>
  );
  
}

export default Home;
