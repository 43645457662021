
import { toast } from 'react-toastify';

/**
+ * A function that displays a toaster message with different error levels.
+ * 
+ * - Error Level 0: Success message.
+ * - Error Level 1: Warning message.
+ * - Error Level 2: Error message.
+ *
+ * @param {string} message - The message to be displayed in the toaster.
+ * @param {number} [errorLevel=0] - The error level of the toaster message. Default is 0.
+ * @param {any} [error=undefined] - The optional error object associated with the toaster message.
+ */
export default function toaster(message, errorLevel = 0, error = undefined) {
    switch (errorLevel) {
        case 0:
        default:
            toast.success(message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose:  1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            })
            break;
        case 1:
            console.warn(message);
            if (error) console.warn(error);

            toast.warning(message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose:  1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            })
            break;
        case 2:
            console.error(message);
            if (error) console.error(error);

            toast.error(`${message} \n Contact admin if the error persists.`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose:  1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            })
            break;
    }
}