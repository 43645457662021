/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_cloud_logic_custom": [
        {
            "name": "supersightApiMvp",
            "endpoint": "https://y5tiirrv76.execute-api.eu-north-1.amazonaws.com/prod",
            "region": "eu-north-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-north-1:16c0d71b-d1ac-4df8-bfa4-1f4f66ca631c",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_X9aeip08w",
    "aws_user_pools_web_client_id": "1b0ks5ashoacrbnlbtqiqo5o49",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-north-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "DowntimesTable-prod",
            "region": "eu-north-1"
        },
        {
            "tableName": "MeasurementsTable-prod",
            "region": "eu-north-1"
        },
        {
            "tableName": "OrganizationsTable-prod",
            "region": "eu-north-1"
        },
        {
            "tableName": "PeopleCountsTable-prod",
            "region": "eu-north-1"
        },
        {
            "tableName": "PhonesTable-prod",
            "region": "eu-north-1"
        },
        {
            "tableName": "SubscriptionsTable-prod",
            "region": "eu-north-1"
        },
        {
            "tableName": "UsersTable-prod",
            "region": "eu-north-1"
        },
        {
            "tableName": "OccupanciesTable-prod",
            "region": "eu-north-1"
        },
        {
            "tableName": "AvailableSeatsTable-prod",
            "region": "eu-north-1"
        }
    ],
    "aws_user_files_s3_bucket": "people-count-bucket155517-prod",
    "aws_user_files_s3_bucket_region": "eu-north-1"
};


export default awsmobile;
